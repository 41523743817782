import api from '@/utils/api'

export function serverList(params) {
  return api.get('user/v1/server', { params:params })
}

export function roleList(params) {
  return api.get('user/v1/role', { params:params })
}

export function couponList(params) {
  return api.post('user/v1/coupons', params) 
}

export function mainGame(params) {
  return api.get('user/v1/roles/games', { params: params })
}
